import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import ogimg from '../images/1default.png' // Importing the default Open Graph image

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  img,
  robots,
  twitterCardContent,
}) {
  return (
    <StaticQuery
      query={graphql`
        query DefaultSEOQuery {
          site {
            siteMetadata {
              title
              description
              author
              siteUrl
            }
          }
        }
      `}
      render={(data) => {
        const metaDescription =
          description || data.site.siteMetadata.description
        const defaultImage = `${data.site.siteMetadata.siteUrl}${ogimg}`
        const metaTags = [
          {
            property: 'og:type',
            content: 'website',
          },
          {
            name: 'robots',
            content: robots ? robots : 'index',
          },
          {
            name: 'twitter:card',
            content: twitterCardContent ? twitterCardContent : 'summary',
          },
        ]

        if (metaDescription) {
          metaTags.push({
            name: 'description',
            content: metaDescription,
          })
          metaTags.push({
            property: 'og:description',
            content: metaDescription,
          })
          metaTags.push({
            name: 'twitter:description',
            content: metaDescription,
          })
        }

        if (title) {
          metaTags.push({
            property: 'og:title',
            content: title,
          })
          metaTags.push({
            name: 'twitter:title',
            content: title,
          })
        }

        // Use the provided img or default to defaultImage
        const image = img || defaultImage

        if (image) {
          const formattedImage = image.startsWith('https://')
            ? image
            : `https:${image}`
          metaTags.push({
            property: 'og:image',
            content: formattedImage,
          })
          metaTags.push({
            property: 'og:image:url',
            content: formattedImage,
          })
          metaTags.push({
            name: 'twitter:image',
            content: formattedImage,
          })
        }

        if (data.site.siteMetadata.author) {
          metaTags.push({
            name: 'twitter:creator',
            content: data.site.siteMetadata.author,
          })
        }

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s`}
            meta={metaTags
              .concat(
                keywords.length > 0
                  ? {
                    name: 'keywords',
                    content: keywords.join(', '),
                  }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
  robots: 'index',
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  img: PropTypes.string,
  robots: PropTypes.string,
  twitterCardContent: PropTypes.string,
}

export default SEO
